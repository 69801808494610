.contenido {
	z-index: 0;
	font-family: "Montserrat";
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	width: 100vw;
	height: 100vh;
	overflow-x: none;
}

.animation-none {
	opacity: 0.01;
	transition: opacity 400ms;
}

.animation-show {
	opacity: 1;
	transition: opacity 400ms;
}

.izq {
	display: flex;
	flex-direction: column;
	background-color: #212224;
	justify-content: flex-end;
	width: 40%;
	padding: 48px;
	color: white;
}

.social-media-icons {
	height: 196px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	bottom: 30px;
}
.social-media-icons svg{
	color: #A68E52;
	transition: 250ms;
}

.social-media-icons svg:hover {
	text-decoration: none;
	font-size: 32px;
	transition: 250ms;
}

.left-info {
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;
	position: relative;
	top: 35px;
}

.left-title {
	font-style: normal;
	font-weight: 900;
	font-size: 36px;
	line-height: 44px;
}

.der {
	width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-end;
	padding: 0px;
}

.home-custom-vertical-line {
	position: absolute;
	color: #c4c4c4;
	height: 48px !important;
	right: 74px;
	top: 30px;
	border: 1px solid #c4c4c4;
}

.language {
	position: relative;
	top: 40px;
	right: 20px;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	text-align: right;
	color: #c4c4c4;
}

.header {
	align-self: center;
	position: relative;
	left: 35px;
}

.header-bold {
	font-family: "Black";
	font-weight: 1000;
	font-size: 72px;
	line-height: 88px;
	letter-spacing: 0.1em;
	margin: 0px;
	font-style: italic;
}

.header-ligth {
	font-family: "Ligth";
	font-weight: 300;
	font-size: 24px;
	line-height: 29px;
	color: #212224;
	position: relative;
	left: 8px;
	margin-top: 0px;
}

.read-more {
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 22px;
	color: #212224;
	position: relative;
	right: 94px;
}

.show-mobile {
	display: none;
}

.show-desktop {
	display: initial;
}

.primary{
	color: #A68E52;
}

@media (max-width: 1000px) {
	.show-mobile {
		display: initial;
	}

	.show-desktop {
		display: none;
	}

	.contenido {
		justify-content: center;
	}

	.read-more {
		color: white;
	}

	.izq {
		width: 100vw;
		padding: 24px;
		height: 100vh;
		justify-content: space-around;
		align-items: center;
		box-sizing: border-box;
	}

	.left-info {
		top: 0px;
	}

	.social-media-icons {
		display: none;
	}

	.der {
		display: none;
	}
}
