.login-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100vw;
	height: 100vh;
}

.login-form-card {
	max-width: 400px;
}
