.loader,
.loader:after {
	border-radius: 50%;
	width: 20em;
	height: 20em;
}
.loader {
	margin: 0px auto;
	font-size: 10px;
	position: relative;
	top: 30vh;
	text-indent: -99999;
	border-top: 1.1em solid rgba(166, 142, 82, 0.2);
	border-right: 1.1em solid rgba(166, 142, 82, 0.2);
	border-bottom: 1.1em solid rgba(166, 142, 82, 0.2);
	border-left: 1.1em solid #a68e52;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

.loader-container {
	background-color: #212529;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
