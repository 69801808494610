.ProjectList-content {
	width: 100vw;
	font-family: "Montserrat";
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	overflow-x: none;
}

.main-info {
	background-color: #212224;
	color: white;
	padding-top: 48px;
	width: 100%;
	height: 50vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	box-sizing: border-box;
}

.title-list {
	font-style: normal;
	font-weight: 1000;
	font-size: 72px;
	line-height: 88px;
	letter-spacing: 0.2em;
}

.subtitle-list {
	font-family: "Ligth";
	position: relative;
	left: 150px;
	font-style: normal;
	font-weight: 200;
	font-size: 72px;
	line-height: 88px;
	letter-spacing: 0.2em;
	color: #a68e52;
}

.paragraph-list {
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 29px;
	text-align: right;
	letter-spacing: 0.1em;
	position: relative;
	top: 20px;
}

.paragraph-list span {
	color: #a68e52;
}

.list-card {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.projects-link {
	text-decoration: none;
}

.ProjectList-title {
	font-size: 72px;
	display: inline;
}

@media (max-width: 1000px) {
	.ProjectList-title {
		font-size: 32px;
	}

	.main-info {
		padding-top: 48px;
		padding: 32px;
		display: block;
		text-align: center;
		flex-direction: column;
		justify-content: space-between;
		box-sizing: border-box;
	}

	.title-list {
		font-size: 38px;
		position: relative;
		top: 25px;
		margin: 0px;
	}

	.subtitle-list {
		font-size: 32px;
		left: 0px;
	}

	.paragraph-list {
		font-size: 18px;
		text-align: center;
	}

	.list-card {
		width: 100vw;
	}
}
