.NotFound {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	flex-direction: column;
	text-align: center;
}

@media (max-width: 1000px) {
	.NotFound {
		padding: 0px 24px;
		box-sizing: border-box;
	}
}
